import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import bookSelectionConfig from "../../config/bookSelection.js"
import BookSelectionUtils from "../../libs/BookSelectionUtils.js";

Vue.use(Vuex)

const defaultOpenAiState = () => {
    return {
        page: 0,
        isDisplayAiChatNavbar: false,
        isDisplayOverlayLoading: false,
        isReSearch: false,
        isStock: false,
        search_word: '',
        kana_readings: [],
        other_readings: '',
        search_results: [],
        search_results_genre: [],
        search_results_title: [],
        selectedCategories: [],
        selectedSortCode: 1,
        selectedPublishDate: [0, -1],
        selectedTopPopularity: -1,
        selectedSellPrices: [0, -1],
        selectedDiscountRate: [0, -1],
        selectedBookAge: [0, -1],
        isDateNotFound: false,
        isSearchError: false,
        isComplete: false,
        isLoading: false,
        isPriceDisplay: true,
        isPublishDateDisplay: true,
        count: null,
        tags: null,
        tagSearchDisplaySize: null,
        vectorSearch: {
            results: [],
            count: 0,
            page: 0,
            isComplete: false,
            isLoading: false,
            isDataNotFound: false,
            isSearchError: false,
        },
        numberOfDuplicates: 0,
        notSearchTagCategory: true,
        userMessageInputValue: null,
        isExternal: false,
        searchKeywordDescription: "",
        relatedKeywordsOfSearchKeyword: [],
        isLoadingDescriptionAndRelatedKeywords: false,
        inputText: null,
        parentURL: null,
        chatItems: [],
        chatItemId: 0,
        chatHistories: [],
        clusterOrder: {},
        separatedCluster: {
            0: [],
            1: []
        },
        clusterBookResults: [],
        pendingRequests: {},
        rateLimitError: {
            status: false,
            errorMessage: null,
        },
        messageLengths: [],
        totalLength: 0,
        maxHistoryLength: bookSelectionConfig.maxNumberOfHistoryLength,    // 最大許容文字数（履歴）,
        displayedVSCatalogIds:new Set()
    }
}

function updateFavoriteFlag(items) {
    const vsCatalogIDs = items.map((item) => item.vs_catalog_id);
    if (0 < vsCatalogIDs.length) {
        return axios.post('/api/library/folder/favorite/items/intersection', {
            vs_catalog_id: vsCatalogIDs,
        }).then(({data: favoriteVsCatalogIDs}) => {
            //favoriteVsCatalogIDsと一致するitemsのisFavoriteをtrueにする
            const favoriteSet = new Set(favoriteVsCatalogIDs);
            items.forEach(item => {
                Vue.set(item, 'isFavorite', favoriteSet.has(item.vs_catalog_id));
            });
        }).catch(e => {
            if (e.response.status !== 403) {
                console.error(e);
            }
        });
    }
}

const moduleOpenAi = {
    namespaced: true,
    state: defaultOpenAiState(),
    getters: {
        getSearchResults(state) {
            // 重複する書誌を除いて返却
            const resultOfRemoved = state.search_results.filter(function (x, i, items) {
                // vs_catalog_id がnullの場合、またはコミックセットの場合はチェックしない（子コミックセットを考慮）
                if (!x.vs_catalog_id || x.vs_catalog_id.startsWith("VC")) {
                    return x
                }
                return items.findIndex(function (y) {
                    return y.vs_catalog_id === x.vs_catalog_id;
                }) === i
            });

            // 重複した書誌の数をセット
            state.numberOfDuplicates = state.search_results.length - resultOfRemoved.length;

            return resultOfRemoved;
        },
        getSearchResultsCount(state) {
            return state.search_results.length;
        },
        getVectorSearchResults(state) {
            return state.vectorSearch.results;
        },
        getVectorSearchResultsCount(state) {
            return state.vectorSearch.results[0] ? state.vectorSearch.results[0].length : 0;
        },
        getIsPublishDateDisplay(state) {
            return state.isPublishDateDisplay;
        },
        getSelectedSellPricePayload(state) {
            let selectedSellPrices = [];
            state.selectedSellPrices.forEach((item, key) => {
                selectedSellPrices[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
            });
            return (!selectedSellPrices[0] && !selectedSellPrices[1]) ? null : selectedSellPrices;
        },
        getSelectedDiscountRatePayload(state) {
            let selectedDiscountRate = [];
            state.selectedDiscountRate.forEach((item, key) => {
                selectedDiscountRate[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
            });
            return (!selectedDiscountRate[0] && !selectedDiscountRate[1]) ? null : selectedDiscountRate;
        },
        getSelectedPublishDatePayload(state) {
            let selectedPublishDate = [];
            state.selectedPublishDate.forEach((item, key) => {
                selectedPublishDate[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
            });
            return (!selectedPublishDate[0] && !selectedPublishDate[1]) ? '' : selectedPublishDate.reverse();
        },
        getSelectedBookAgePayload(state) {
            let selectedBookAge = [];
            state.selectedBookAge.forEach((item, key) => {
                selectedBookAge[key] = (item === VueConfig.search.MIN_NO_SPECIFIED_VALUE || item === VueConfig.search.MAX_NO_SPECIFIED_VALUE) ? '' : item;
            });
            return (!selectedBookAge[0] && !selectedBookAge[1]) ? '' : selectedBookAge.reverse();
        },
        getIsComplete(state) {
            return state.isComplete;
        },
        getIsDisplayAiChatNavbar(state) {
            return state.isDisplayAiChatNavbar;
        },
        getVectorSearchIsDataNotFound(state) {
            return state.vectorSearch.isDataNotFound;
        },
        getVectorSearchIsSearchError(state) {
            return state.vectorSearch.isSearchError;
        },
        getUserMessageInputValue(state) {
            return state.userMessageInputValue;
        },
        getIsExternal(state) {
            return state.isExternal;
        },
        getSearchKeywordDescription(state) {
            return state.searchKeywordDescription
        },
        getRelatedKeywordsOfSearchKeyword(state) {
            return state.relatedKeywordsOfSearchKeyword;
        },
        getIsLoadingDescriptionAndRelatedKeywords(state) {
            return state.isLoadingDescriptionAndRelatedKeywords;
        },
        getInputText(state) {
            return state.inputText;
        },
        getParentURL(state) {
            return state.parentURL;
        },
        getChatItems(state) {
            return state.chatItems;
        },
        getChatItemId(state) {
            return state.chatItemId;
        },
        getSeparatedCluster(state) {
            return state.separatedCluster;
        },
        getClusterBookResults(state) {
            return state.clusterBookResults;
        },
        isChatLoading: (state) => (id, type) => {
            if (!state.pendingRequests || !state.pendingRequests[id]) return false;
            return state.pendingRequests[id] === type;
        },
        getRateLimitError(state) {
            return state.rateLimitError;
        },
        getPendingRequests(state) {
            return state.pendingRequests;
        },
        isAllClusterSearchLoaded(state) {
            return !state.clusterBookResults.some(cluster => cluster.isLoading);
        },
        getChatHistories(state) {
            return state.chatHistories
        },
    },
    actions: {
        async research({dispatch}) {
            dispatch("resetSearchState");
            await dispatch('searchItem');
            await dispatch('vectorSearchItems');
        },
        async karteSearch({dispatch}) {
            dispatch("resetSearchState");
            await dispatch('karteSearchItems');
        },
        resetSearchState({commit}) {
            commit('mutateIsReSearch', true);
            commit('resetPage');
            commit('resetSearchResults');
            commit('resetVectorSearchState');
            commit('mutateIsDateNotFound', false);
            commit('mutateIsSearchError', false);
            commit('mutateIsComplete', false);
            commit('mutateIsDisplayOverlayLoading', true);
        },
        searchItem({state, commit, getters}) {
            if (state.isComplete) {
                return;
            }

            commit('incrementPage');
            commit('mutateIsLoading', true);
            let optionalDisplaySize = null;
            optionalDisplaySize = VueConfig.search.SEARCH_ITEMS_PER_PAGE;
            const maxItemNumber = optionalDisplaySize ? optionalDisplaySize : VueConfig.search.SEARCH_ITEMS_PER_PAGE;

            return axios.get('/api/search', {
                params: {
                    page: state.page,
                    search_word: state.search_word,
                    kana_readings: state.kana_readings,
                    other_readings: state.other_readings,
                    conditions_stock: state.isStock ? '2' : '1',
                    conditions_sorting: state.selectedSortCode,
                    ...(state.selectedCategories.length ? {conditions_category: state.selectedCategories} : ''),
                    ...(
                        getters.getIsPriceDisplay ?
                            getters.getSelectedSellPricePayload ? {conditions_price: getters.getSelectedSellPricePayload} : '' :
                            getters.getSelectedDiscountRatePayload ? {conditions_rate: getters.getSelectedDiscountRatePayload} : ''),
                    ...(
                        getters.getIsPublishDateDisplay ?
                            getters.getSelectedPublishDatePayload ? {conditions_age: getters.getSelectedPublishDatePayload} : '' :
                            getters.getSelectedBookAgePayload ? {conditions_age: getters.getSelectedBookAgePayload} : ''
                    ),
                    ...(state.selectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE ? {conditions_popularGoods: state.selectedTopPopularity} : ''),
                    tags: state.tags,
                    display_size: optionalDisplaySize,
                    notSearchTagCategory: state.notSearchTagCategory,
                    ...(state.isExternal ? {isExternalSearch: state.isExternal} : ''),
                },
            })
                .then(({data}) => {
                    if (!data) {
                        return;
                    }

                    commit('applySearchResults', data.items);
                    commit('mutateIsDisplayOverlayLoading', false);
                    if (Number.isFinite(data.count)) {
                        commit('mutateCount', data.count);
                    }

                    //追加の検索結果がない場合
                    if (data.items.length === 0 || data.items.length < maxItemNumber) {
                        commit('mutateIsComplete', true);
                    }

                    //検索結果が全くない
                    if (getters.getSearchResultsCount === 0) {
                        commit('mutateIsDateNotFound', true);
                    }
                })
                .catch((error) => {
                    commit('mutateIsSearchError', true);
                    commit('mutateIsLoading', false);
                }).finally(() => {
                    commit('mutateIsLoading', false);
                });
        },
        vectorSearchItems({state, commit, getters}, payload) {
            if (state.vectorSearch.isComplete) {
                return;
            }
            const api = "/api/vectorSearch";
            const maxItemNumber = VueConfig.search.SEARCH_ITEMS_PER_PAGE;

            state.vectorSearch.page === 1 && commit('mutateVectorSearchPage', 2);
            commit('incrementVectorSearchPage');
            commit('mutateIsVectorSearchLoading', true);

            return axios
                .get(api, {
                    params: {
                        page: state.vectorSearch.page,
                        search_word: state.search_word,
                        kana_readings: state.kana_readings,
                        other_readings: state.other_readings,
                        conditions_stock: state.isStock ? '2' : '1',
                        conditions_sorting: state.selectedSortCode,
                        ...(state.selectedCategories.length ? {conditions_category: state.selectedCategories} : ''),
                        ...(
                            getters.getIsPriceDisplay ?
                                getters.getSelectedSellPricePayload ? {conditions_price: getters.getSelectedSellPricePayload} : '' :
                                getters.getSelectedDiscountRatePayload ? {conditions_rate: getters.getSelectedDiscountRatePayload} : ''),
                        ...(
                            getters.getIsPublishDateDisplay ?
                                getters.getSelectedPublishDatePayload ? {conditions_age: getters.getSelectedPublishDatePayload} : '' :
                                getters.getSelectedBookAgePayload ? {conditions_age: getters.getSelectedBookAgePayload} : ''
                        ),
                        ...(state.selectedTopPopularity !== VueConfig.search.MAX_NO_SPECIFIED_VALUE ? {conditions_popularGoods: state.selectedTopPopularity} : ''),
                        ...(state.tags ? {tags: state.tags} : ''),
                        ...(payload && payload.a ? {a: payload.a} : ''),
                        ...(payload && payload.b ? {b: payload.b} : ''),
                        ...(payload && payload.img_weight ? {img_weight: payload.img_weight} : ''),
                        display_size: maxItemNumber,
                        ...(state.isExternal ? {isExternalSearch: state.isExternal} : ''),
                    },
                })
                .then(({data}) => {
                    if (!data) {
                        return;
                    }

                    if (state.vectorSearch.page <= 2) {
                        commit('applyVectorSearchResults', [data.items]);
                    } else {
                        commit('applySearchResults', data.items);
                        commit('applyVectorSearchResults', [data.items]); // 検証用に配列で持たせている
                    }

                    if (Number.isFinite(data.count)) {
                        commit('mutateVectorSearchCount', data.count);
                    }

                    //追加の検索結果がない場合
                    if (data.items.length === 0 || data.items.length < maxItemNumber) {
                        commit('mutateIsVectorSearchComplete', true);
                    }

                    //検索結果が全くない
                    if (getters.getVectorSearchResultsCount === 0) {
                        commit('mutateIsVectorDataNotFound', true);
                    }
                    commit('mutateIsVectorSearchLoading', false);
                })
                .catch((error) => {
                    commit('mutateIsVectorSearchError', true);
                    commit('mutateIsVectorSearchLoading', false);
                });
        },
        karteSearchItems({state, commit, getters}) {
            const api = "/api/karteSearch";
            return axios
                .get(api, {
                    params: {
                        search_word: state.search_word
                    },
                })
                .then(({data}) => {
                    if (!data) {
                        return;
                    }
                    updateFavoriteFlag(data.items);
                    commit('applySearchResults', data.items)
                    if (Number.isFinite(data.count)) {
                        commit('mutateVectorSearchCount', data.count);
                    }
                    //検索結果が全くない
                    if (getters.getVectorSearchResultsCount === 0) {
                        commit('mutateIsVectorDataNotFound', true);
                    }
                    commit('mutateIsVectorSearchLoading', false);
                })
                .catch((error) => {
                    commit('mutateIsVectorSearchError', true);
                    commit('mutateIsVectorSearchLoading', false);
                });
        },
        changeStockCondition({dispatch, commit}, payload) {
            commit('mutateIsStock', payload);
            dispatch('research');
        },
        getDescriptionAndRelatedKeywordsOfSearchWords({state, commit}, items) {
            if (!state.search_word || state.search_word.length > 20) {
                return;
            }
            commit("mutateIsLoadingDescriptionAndRelatedKeywords", true);

            // 必要なフィールドのみを抜き出す関数
            const selectFields = (arr) => arr.map(item => ({
                title: item.title,
                authors: item.authors,
                publisher: item.publisher,
                briefSummary: item.briefSummary,
                keywords: item.keywords
            }));

            // シャッフル関数
            const shuffleArray = (arr) =>
                arr
                    .map(value => ({value, sort: Math.random()}))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({value}) => value);

            const existsAIFieldItems = shuffleArray(selectFields(items.filter(item => item.briefSummary || item.keywords?.length)));
            let randomItems = [];
            // AIに渡す最大書籍数
            const maxProvidesCount = 20;
            if (existsAIFieldItems.length >= maxProvidesCount) {
                randomItems = existsAIFieldItems.slice(0, maxProvidesCount);
            } else {
                // AI系フィールドを含む書籍が20冊未満の場合は、含まない書籍も入れる。
                const otherItems = shuffleArray(selectFields(items.filter(item => !item.briefSummary && !item.keywords?.length)));
                randomItems = [...existsAIFieldItems, ...otherItems].slice(0, maxProvidesCount);
            }

            const api = "/api/search/searchRelatedKeyWordsWithDescription"
            return axios
                .post(api, {
                    search_word: state.search_word,
                    search_results: randomItems,
                    result_count: items.length
                })
                .then(({data}) => {
                    if (!data) {
                        return;
                    }
                    const description = data.description;
                    const relatedKeywords = data.relatedKeywords;
                    if (description) {
                        commit("mutateSearchKeywordDescription", description);
                    }
                    if (relatedKeywords && relatedKeywords.length > 0) {
                        commit("mutateRelatedKeywordsOfSearchKeyword", relatedKeywords);
                    }
                })
                .catch((error) => {

                }).finally(() => {
                    commit("mutateIsLoadingDescriptionAndRelatedKeywords", false);
                });
        },
        async executeClusterSearch({dispatch, getters, commit}) {
            const inputText = getters.getInputText;

            // 両方のAPIリクエストを並列で起動
            const recommendPromise = dispatch('retrieveAiComment', inputText);
            const clustersPromise = dispatch('retrieveClusters', inputText);

            // カウンターの更新
            commit('updateChatItemId');
            const recommendId = getters.getChatItemId;

            commit('updateChatItemId');
            const clustersId = getters.getChatItemId;

            // ローディング用フラグを追加
            commit('addPendingRequest', {id: recommendId, type: 'comment'});
            commit('addPendingRequest', {id: clustersId, type: 'clusters'});

            // recommendStatusの完了を待機
            const recommendResult = await recommendPromise;

            commit('removePendingRequest', {id: recommendId, type: 'comment'});
            // recommendStatusの結果を履歴に追加
            if (recommendResult.chatHistory) {
                commit('addChatHistory', recommendResult.chatHistory);
            }
            if (recommendResult.assistantText) {
                commit('addChatItems', {
                    id: recommendId,
                    role: 'assistant',
                    content: {
                        text: recommendResult.assistantText,
                        searchResults: null
                    }
                });
                commit('addChatHistory', {
                    role: 'assistant',
                    content: recommendResult.assistantText
                });
            } else {
                // エラーの場合、画面上は何も表示しないが、ローディング制御の為にchatItemsには追加する
                commit('addChatItems', {
                    id: recommendId,
                    role: 'assistant',
                    content: {
                        text: null,
                        searchResults: null
                    }
                });
            }

            // retrieveClustersの完了を待機
            const clustersResult = await clustersPromise;

            commit('removePendingRequest', {id: clustersId, type: 'clusters'});
            // retrieveClustersの結果を履歴に追加
            if (clustersResult.chatHistory) {
                commit('addChatHistory', clustersResult.chatHistory);
            } else {
                commit('addChatItems', {
                    id: clustersId,
                    role: 'assistant',
                    content: {
                        text: '申し訳ありません。エラーが発生しました。\n質問内容を変更して再度お試し頂くか、しばらくしてからもう一度お試しください。',
                        searchResults: null
                    }
                });
                return;
            }

            // retrieveClustersの処理が完了したら、executeBookSearchを実行
            if (clustersResult && clustersResult.clusters) {
                // ここでexecuteBookSearchを実行
                await dispatch('executeBookSearch', clustersResult.clusters);
            }
        },
        //AIの返答を取得
        async retrieveAiComment({getters, commit, dispatch}, inputText) {
            commit('mutateInputText', null);
            commit('mutateIsComplete', false);
            return axios.post("/api/retrieveSelectionResponse", {
                input_text: inputText,
                chat_history: getters.getChatHistories
            }).then(response => {
                const res = response.data;

                const assistantText = res.response_text;
                const responseInputText = res.input_text; // サーバ側で要約されたユーザの入力値

                return {
                    assistantText: assistantText ? assistantText : null,
                    chatHistory: responseInputText ? {
                        role: 'user',
                        content: responseInputText
                    } : null
                }
            }).catch(error => {
                // 利用回数上限
                if (error.response.status === 429) {
                    commit('mutateRateLimitError', {
                        status: true,
                        message: '本日のご利用回数の上限に達しましたので、翌日以降のご利用をお願いします。'
                    });
                    return {chatHistory: null};
                }
                return {chatHistory: null};
            });
        },
        //会話履歴等からクラスターの一覧を取得
        retrieveClusters({getters, dispatch, commit}, inputText) {
            // 処理開始前にclusterBookResultsをクリア
            commit('initClusterBookResults', []);
            // 表示済みVSCatalogIDをクリア
            commit('resetDisplayedVSCatalogIds');
            return axios.post("/api/retrieveClusters", {
                input_text: inputText,
                chat_history: getters.getChatHistories
            }).then(async response => {
                const res = response.data;
                const clusters = res.clusters;

                if (Object.keys(clusters).length) {
                    commit('mutateClusterOrder', clusters);
                    dispatch('separateCluster', clusters);

                    return {
                        clusters: clusters,
                        chatHistory: {
                            role: 'assistant',
                            content: {theme_list: clusters}
                        }
                    }
                }
            }).catch(error => {
                return {clusters: null, chatHistory: null};
            })
        },
        async executeBookSearch({getters, dispatch, commit}) {
            if (Object.keys(getters.getSeparatedCluster).length) {

                // 初期値を表示するため、まずsearchClusterBooksを呼び出す
                const searchPromise = dispatch('searchClusterBooks', getters.getSeparatedCluster);

                // 初期値が設定された直後に一度結果を取得して表示
                const initialResults = JSON.parse(JSON.stringify(getters.getClusterBookResults || []));
                commit('addChatItems', {
                    id: getters.getChatItemId,
                    role: 'assistant',
                    content: {
                        text: null,
                        searchResults: initialResults
                    }
                });

                // 全ての検索が完了するのを待つ
                await searchPromise;
                commit('mutateIsComplete', true);
            }
        },
        //取得したクラスターを分割してストアにコミット
        separateCluster({commit, state}, clusters) {
            commit('initSeparatedCluster');
            const chunk = 2;
            const keys = state.clusterOrder.map(item => item.title);
            const firstPair = {};
            firstPair[keys[0]] = clusters[keys[0]].description;
            commit('mutateSeparatedCluster', {index: 0, value: firstPair});
            let now = 1;
            for (let i = 1; i < keys.length; i += chunk) {
                const pairsChunk = {};
                const keySlice = keys.slice(i, i + chunk);
                keySlice.forEach(key => {
                    pairsChunk[key] = clusters[key].description;
                });
                commit('mutateSeparatedCluster', {index: now, value: pairsChunk});
                now = 1 - now;
            }
        },
        //各クラスターに対して書籍の検索を実行
        searchClusterBooks({commit, state,dispatch}, clusters) {
            if (!state.clusterBookResults) {
                commit('initClusterBookResults', []);
            }

            // 初期値を先に作成
            if (Array.isArray(state.clusterOrder)) {
                state.clusterOrder.forEach(item => {
                    const initialResult = {
                        title: item.title,
                        description: item.description,
                        isLoading: true,
                        items: []
                    };
                    commit('addClusterBookResults', initialResult);
                });
            }

            // 全グループの処理が完了するPromiseの配列
            const groupPromises = [];

            // グループごとに処理を実行（並列処理）
            Object.keys(clusters).forEach(groupKey => {
                const group = clusters[groupKey];

                // このグループの処理を表すPromise
                const groupPromise = (async () => {
                    // グループ内のクラスターを順番に処理
                    for (let index = 0; index < group.length; index++) {
                        const flatClusters = group[index];

                        try {
                            const response = await axios.post("/api/searchClusterBooks", {
                                clusters: flatClusters
                            });

                            const res = response.data;

                            // 返されたレスポンスの各キーに対して結果を更新
                            Object.keys(res).forEach(key => {
                                // keyNameに対応する説明文を見つける
                                const description = flatClusters[key] || '';
                                let items = res[key];
                                dispatch('filterUniqueItemsWithLimit', items).then(filteredItems => {
                                    const result = {
                                        title: key,
                                        description: description,
                                        isLoading: false,
                                        items: filteredItems
                                    };
                                    commit('updateClusterBookResults', {key: key, data: result});
                                });
                            });
                        } catch (error) {
                            // エラーが発生した場合、該当するクラスター全てを更新
                            Object.keys(flatClusters).forEach(keyName => {
                                const description = flatClusters[keyName];
                                commit('updateClusterBookResults', {
                                    key: keyName,
                                    data: {
                                        title: keyName,
                                        description: description,
                                        isLoading: false,
                                        error: true,
                                        errorMessage: '申し訳ありません。お探しの本は見つかりませんでした。',
                                        items: []
                                    }
                                });
                            });
                        }
                    }
                })();

                // グループのPromiseを配列に追加
                groupPromises.push(groupPromise);
            });

            // 全てのグループの処理が完了したPromiseを返す
            return Promise.all(groupPromises);
        },
        filterUniqueItemsWithLimit({state, commit}, items){
            let filteredItems = [];
            const maxLength  = bookSelectionConfig.numberOfClusterSearchItems;
            let skipped = [];
            for(let item of items){
                let vsCatalogId = item.vs_catalog_id;
                if(state.displayedVSCatalogIds.has(vsCatalogId)){
                    skipped.push(vsCatalogId);
                    continue;
                }
                commit("addDisplayedVSCatalogIds",vsCatalogId);
                filteredItems.push(item);
                if(filteredItems.length>=maxLength){
                    break;
                }
            }
            return filteredItems.concat(skipped.slice(0, maxLength - filteredItems.length));
        }
    },
    mutations: {
        mutateIsDisplayAiChatNavbar(state, value) {
            state.isDisplayAiChatNavbar = value;
        },
        mutateIsReSearch(state, value) {
            state.isReSearch = value;
        },
        mutateIsStock(state, value) {
            state.isStock = value;
        },
        incrementPage(state) {
            state.page++;
        },
        incrementVectorSearchPage(state) {
            state.vectorSearch.page++;
        },
        mutatePage(state, value) {
            state.page = value;
        },
        mutateVectorSearchPage(state, value) {
            state.vectorSearch.page = value;
        },
        mutateSearchWord(state, value) {
            state.search_word = value;
        },
        mutateKanaReadings(state, value) {
            state.kana_readings = value;
        },
        mutateOtherReadings(state, value) {
            state.other_readings = value;
        },
        applySearchResults(state, value) {
            state.search_results.push(...value);
        },
        applySearchResultsGenre(state, value) {
            state.search_results_genre.push(value);
        },
        applySearchResultsTitle(state, value) {
            state.search_results_title.push(...value);
        },
        applyVectorSearchResults(state, value) {
            state.vectorSearch.results.push(...value);
        },
        mutateIsDateNotFound(state, value) {
            state.isDateNotFound = value;
        },
        mutateIsVectorDataNotFound(state, value) {
            state.vectorSearch.isDataNotFound = value;
        },
        mutateIsSearchError(state, value) {
            state.isSearchError = value;
        },
        mutateIsVectorSearchError(state, value) {
            state.vectorSearch.isSearchError = value;
        },
        mutateIsComplete(state, value) {
            state.isComplete = value;
        },
        mutateIsVectorSearchComplete(state, value) {
            state.vectorSearch.isComplete = value;
        },
        mutateIsLoading(state, value) {
            state.isLoading = value;
        },
        mutateIsVectorSearchLoading(state, value) {
            state.vectorSearch.isLoading = value;
        },
        mutateSelectedCategories(state, value) {
            state.selectedCategories = value;
        },
        mutateSelectedSellPrices(state, value) {
            state.selectedSellPrices = value;
        },
        mutateSelectedDiscountRate(state, value) {
            state.selectedDiscountRate = value;
        },
        mutateSelectedPublishDate(state, value) {
            state.selectedPublishDate = value;
        },
        mutateSelectedBookAge(state, value) {
            state.selectedBookAge = value;
        },
        mutateSelectedTopPopularity(state, value) {
            state.selectedTopPopularity = value;
        },
        mutateSelectedSortCode(state, value) {
            state.selectedSortCode = value;
        },
        resetSearchResults(state) {
            state.search_results = [];
        },
        resetPage(state) {
            state.page = 0;
        },
        // vuetifyのresetでは任意の値に初期化が出来ないためstateをコピーして初期化
        resetState(state) {
            let defaultState = defaultOpenAiState();
            delete defaultState.search_word;
            delete defaultState.search_results;
            delete defaultState.search_results_genre;
            delete defaultState.page;
            delete defaultState.kana_readings;
            delete defaultState.other_readings;
            Object.assign(state, defaultState)
        },
        // べクトル検索のstateを初期化
        resetVectorSearchState(state) {
            state.vectorSearch = {
                results: [],
                count: 0,
                page: 0,
                isComplete: false,
                isLoading: false,
                isDataNotFound: false,
                isSearchError: false,
            };
        },
        mutateIsDisplayOverlayLoading(state, value) {
            state.isDisplayOverlayLoading = value;
        },
        mutateCount(state, value) {
            state.count = value;
        },
        mutateVectorSearchCount(state, value) {
            state.vectorSearch.count = value;
        },
        mutateTags(state, payload) {
            state.tags = payload;
        },
        mutateTagSearchDisplaySize(state, value) {
            state.tagSearchDisplaySize = value;
        },
        mutateNotSearchTagCategory(state, value) {
            state.notSearchTagCategory = value;
        },
        setUserMessageInputValue(state, value) {
            state.userMessageInputValue = value;
        },
        setIsExternal(state, value) {
            state.isExternal = value;
        },
        mutateSearchKeywordDescription(state, value) {
            state.searchKeywordDescription = value;
        },
        mutateRelatedKeywordsOfSearchKeyword(state, value) {
            state.relatedKeywordsOfSearchKeyword = value;
        },
        mutateIsLoadingDescriptionAndRelatedKeywords(state, value) {
            state.isLoadingDescriptionAndRelatedKeywords = value;
        },
        mutateInputText(state, value) {
            state.inputText = value;
        },
        mutateParentURL(state, value) {
            state.parentURL = value;
        },
        updateChatItemId(state, value) {
            state.chatItemId++;
        },
        addChatItems(state, items) {
            state.chatItems.unshift(items);
        },
        addChatHistory(state, itemObject) {
            const contentLength = BookSelectionUtils.getContentLength(itemObject.content);

            state.chatHistories.push(itemObject);
            state.messageLengths.push(contentLength);
            state.totalLength += contentLength;

            // 最大長を超えた場合は古いメッセージを削除
            if (state.totalLength <= state.maxHistoryLength) {
                return; // 閾値内なら何もしない
            }

            // 閾値を超えたら、先頭から必要なだけ削除
            while (state.totalLength > state.maxHistoryLength && state.chatHistories.length > 0) {
                const removedLength = state.messageLengths.shift();
                state.totalLength -= removedLength;
                state.chatHistories.shift();
            }
        },
        initSeparatedCluster(state) {
            state.separatedCluster = {
                0: [],
                1: []
            }
        },
        mutateSeparatedCluster(state, payload) {
            const index = payload.index;
            const value = payload.value;
            state.separatedCluster[index].push(value);
        },
        initClusterBookResults(state, value) {
            state.clusterBookResults = value;
        },
        addClusterBookResults(state, value) {
            state.clusterBookResults.push(value);
        },
        updateClusterBookResults(state, {key, data}) {
            // clusterBookResultsが存在することを確認
            if (!state.clusterBookResults) {
                Vue.set(state, 'clusterBookResults', []);
            }

            const index = state.clusterBookResults.findIndex(item => item.title === key);
            if (index !== -1) {
                // Vue.setを使用して配列の要素を更新
                Vue.set(state.clusterBookResults, index, {
                    ...state.clusterBookResults[index],
                    ...data
                });
            } else {
                // 存在しない場合は追加
                state.clusterBookResults.push({
                    title: key,
                    ...data
                });
            }

            // chatItemsの更新
            if (state.chatItems && state.chatItems.length > 0) {
                // assistantメッセージ内のsearchResultsを探す
                const chatItems = state.chatItems.filter(item =>
                  item.role === 'assistant' &&
                  item.content &&
                  item.content.searchResults
                );

                // 最新のassistantメッセージから検索
                for (let i = chatItems.length - 1; i >= 0; i--) {
                    const item = chatItems[i];
                    const resultIndex = item.content.searchResults.findIndex(result => result.title === key);

                    if (resultIndex !== -1) {
                        // 見つかったsearchResultを更新
                        Vue.set(item.content.searchResults, resultIndex, {
                            ...item.content.searchResults[resultIndex],
                            ...data
                        });
                        // 一致するものが見つかったら更新を終了
                        break;
                    }
                }
            }
        },
        addPendingRequest(state, items) {
            if (!state.pendingRequests) {
                Vue.set(state, 'pendingRequests', {});
            }
            Vue.set(state.pendingRequests, items.id, items.type);
        },
        removePendingRequest(state, items) {
            const id = items.id;
            // そのIDの状態が存在することを確認
            if (state.pendingRequests[id]) {
                // タイプのプロパティを削除
                Vue.delete(state.pendingRequests, id);
            }
        },
        mutateClusterOrder(state, value) {
            const resultArray = [];
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    resultArray.push({
                        title: key,
                        description: value[key].description
                    });
                }
            }
            state.clusterOrder = resultArray;
        },
        mutateRateLimitError(state, value) {
            state.rateLimitError = {
                status: value.status,
                errorMessage: value.message
            };
        },
        clearChatHistory(state) {
            state.inputText = null;
            state.chatItems = [];
            state.chatHistories = [];
            state.chatItemId = 0;
            state.pendingRequests = {};
            state.rateLimitError = {
                status: false,
                errorMessage: null,
            };
        },
        addDisplayedVSCatalogIds(state,value){
          state.displayedVSCatalogIds.add(value)
        },
        resetDisplayedVSCatalogIds(state){
            state.displayedVSCatalogIds = new Set();
        }
    },
}

export {moduleOpenAi};