const BookSelectionUtils = {
  getContentLength(content) {
    if (typeof content === 'string') {
      return content.length;
    } else if (content !== null && typeof content === 'object') {
      return JSON.stringify(content).length;
    }
    return 0;
  }
};

export default BookSelectionUtils;